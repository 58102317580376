import jquery from 'jquery';
window.$ = window.jQuery = jquery;

import * as common from './common';
import * as i18n from '../i18n/jquery.i18n';
import * as navigationService from './navigation-service';
import { config } from './config';
import * as utils from './utils';
import '../../layer/layer';

export { common, i18n, navigationService, config, utils };

var token;
var expiryDate;
//rooturl = "http://192.168.0.105:16330"; //服务访问地址
/**
 * 设置未来(全局)的AJAX请求默认选项
 * 主要设置了AJAX请求添加token
 */
export function initajax() {
	if (token == undefined || token == "undefined" || token == null || token == "") { //过期
		token = localStorage.wavpayToken;
		expiryDate = localStorage.expiryDate;
	}
	$.ajaxSetup({
		beforeSend: function(xhr) {
			xhr.setRequestHeader('Authorization', "Bearer " + token);
		}
	});
}
/**
 * 在接受到数据后做统一处理 
 */
$(document).ajaxError(function(event, request, settings) {
	// console.log(request.status);
	// console.log(settings.url);
	// console.log(token);
	var posturl = settings.url;
	var ispass = true; //是否验证
	for (let i = 0; i < config.undealArr.length; i++) {
		let oul = config.undealArr[i];
		if (posturl.indexOf(oul) > -1) {
			ispass = false;
		}
	}
	if (ispass) {
		var responseJSON = request.responseJSON;
		var backstatus=0;
		if(undefined!=responseJSON){
			backstatus = responseJSON.status;
		}
		if (request.status == 401) {
			//销毁token
			delete localStorage.wavpayToken;
			delete localStorage.expiryDate;
			navigationService.goToPage("Login.html");
		}else if(backstatus == 1011 && config.authorCode!=1011){
			//销毁token
			delete localStorage.wavpayToken;
			delete localStorage.expiryDate;
			config.authorCode=1011;
			alert("Your account has been logged in elsewhere. Please log in again if necessary.");
			navigationService.goToPage("Login.html");
		}
	}

});

//监听密码明文变化
$(document).on('click', '.passwordDiv span', function() {
	var spanov = $(this).find("i");
	var className = spanov.hasClass("fa-eye");
	var inputOv = $(this).parent().find(":input");
	if (className) {
		inputOv.attr("type", "password");
		spanov.removeClass("fa-eye");
		spanov.addClass("fa-eye-slash");
	} else {
		inputOv.attr("type", "text");
		spanov.addClass("fa-eye");
		spanov.removeClass("fa-eye-slash");
	}
});

// 金额输入格式化
$(document).on('blur', '.rmAmount', function () {
	var amount = $(this).val();
	var b = Number(amount);
	if (b) {
		amount = parseFloat(amount).toFixed(2);
		var fmtAmount = utils.numberFormat(amount, 3, ",");
		$(this).val(fmtAmount);
	} else {
		$(this).val("");
		layer.msg("It's not correct a number", {
			title: 'Information',
			btn: 'OK'
		});
	}

});

$(function () {
	$("header[data-html]").each(function () {
		// console.log($(this).attr("data-html"))
		$(this).load($(this).attr("data-html"), function () {
			i18n.loadLocalization($(this));
		});
	});
	$("nav[data-html]").each(function () {
		$(this).load($(this).attr("data-html"), function () {
			i18n.loadLocalization($(this));
		});
	});
	$("div[data-html]").each(function () {
		$(this).load($(this).attr("data-html"), function () {
			i18n.loadLocalization($(this));
		});
	});
});

//例外路径
initajax();
